.Proxy {
  font-family: Arial;
  font-size: 16px;
  text-align: left;
  color: #000;
}

.Proxy .ReaderInput {
  margin: 1% 5% 0% 5%;
  float: right;
  min-width: 290px;
}

.Proxy .ReaderIP {
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.Proxy input {
  margin-left: 5px;
  padding: 0px 0px 0px 10px;
  width: 250px;
  height: 30px;
  border: solid 1px #979797;
  background-color: #fff;
  border-radius: 3px;
}

.Proxy button {
  margin-left: 10px;
  padding: 10px 8px 10px 8px;
  text-align: center;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  font-weight: bold;
}

.Proxy .disabled {
  background-color: #D3D3D3;
}

.Proxy button:hover {
  background-color: #9F9F9F;
}

/*width: 1280px;
height: 650px;*/
.Proxy .Main {
  position: absolute;
  width: 85%;
  height: 90%;
  min-width: 1200px;
  min-height: 700px;
  top: 7%;
  left: 3%;
  border: solid 1px #000;
  background-color: #fffefe;
  padding: 5px 60px 10px 60px;
}

.Proxy h1 {
  font-size: 26px;
}

.Proxy .Host {
  margin: 30px 0px 0px 0px;
  font-weight: bold;
  font-size: 18px;
}

.Proxy .Images {
  float: right;
  position: relative;
  top: -150px;
}

.Proxy .Power {
  width: 515px;
  height: 160px;
  margin-right: 50px;
}

.Proxy .Logo {
  width: 300px;
  height: 145px;
}

.Proxy .Status {
  margin: 25px 0px 15px 0px;
  padding: 10px 15px 10px 15px;
  border: solid 2px #b7b7b7;
  font-size: 26px;
  height: 35%;
}

.Proxy .Status .Green {
  margin-left: 5px;
  width: 15px;
  height: 15px;
}

.Proxy .Columns {
  position: absolute;
  width: 100%;
  margin-top: 0px;
}

.Proxy .Column {
  display: inline-block;
  width: 40%;
}

.Proxy .Column2 {
  display: inline-block;
  width: 20%;
}

.Proxy .DetailColumns {
  margin-top: 15px;
  font-size: 16px;
  width: 100%;
}

.Proxy .DetailColumn1 {
  display: inline-block;
  width: 25%;
  vertical-align: top;
}

.Proxy .DetailColumn2 {
  display: inline-block;
  width: 25%;
  font-weight: bold;
  vertical-align: top;
}

.Proxy .DetailField {
  height: 20px;
  min-height: 20px;
}

.Proxy .DetailField2 {
  overflow: hidden;
}

.Proxy .DetailField .AccelDetails {
  font-size: 14px;
  color: #424949;
}

.Proxy .Break {
  height: 20px;
  min-height: 19px;
}

.Proxy .ControlTabs {
  height: 4%;
  cursor: pointer;
}

.Proxy .Tab {
  height: 75%;
  min-width: 10%;
  display: inline-block;
  border: solid 1px #979797;
  border-bottom-style: none;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  text-align: center;
  padding-top: .5%;;
  background-color: #d2cfcf;
  color: white;
}

.Proxy .SelectedTab {
  font-weight: bold;
  background-color: white;
  color: #000;
}

.Proxy .Control {
  height: 26%;
  padding: 5px 10px 5px 15px;
  border: solid 2px #b7b7b7;
  font-size: 16px;
  overflow: auto;
}

.Proxy .Control .Panel {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
}

.Proxy .Fields {
  margin-top: 18px;
}

.Proxy .Field {
  display: inline-block;
  width: 15%;
}

.Proxy .Field2 {
  display: inline-block;
  width: 15%;
  vertical-align: bottom;
  color: #424949;
}

.Proxy .Field2 .Subnote {
  font-size: 12px;
  font-weight: bold;
}

.Proxy .Value {
  display: inline-block;
  width: 35%;
}

.Proxy .Value2 {
  display: inline-block;
  vertical-align: top;
  width: 5%;
}

.Proxy .Value3 {
  display: inline-block;
  width: 80%;
  margin-bottom: 5px;
}

.Proxy .Checkbox {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0px 5px 0px 10px;
  vertical-align: middle;
}

.Proxy .ResetMCU {
 float: right;
}

/* Control GPIO Form */
.Proxy .FieldsGpio{
  margin-top: 10px;
}

.Proxy .FieldsGpio2{
  margin-top: 20px;
}

.Proxy .FieldGpio {
  display: inline-block;
  width: 20%;
}

.Proxy .ValueGpio {
  display: inline-block;
  width: 30%;
}

.Proxy .ValueGpio2 {
  width: 80%;
}

.Proxy .ValueGpio3 {
  margin-top: 20px;
  width: 80%;
}

.Proxy .ValueGpio3 button {
  float: right;
}

.Proxy .DetailsGpio {
  font-size: 14px;
  width: 80%;
  height: 50px;
  margin-top: 20px;
  text-align: center;
}

.Proxy .StatusText {
  position: absolute;
  left:15px;
  top: 35%;
  font-size: 24px;
  transform: rotate(0.75turn);
  color: #b7b7b7;
}

.Proxy .ControlText {
  position:absolute;
  font-size: 24px;
  transform: rotate(0.75turn);
  color: #b7b7b7;
  left: 10px;
  top: 75%;
}

.Proxy .Loading {
  position: absolute;
  top: 55%;
  font-size: 20px;
  font-weight: bold;
  color: #5575FB;
  width: 90%;
  text-align: center;
  z-index: 100;
}

.Proxy .BadTemp {
  color: #E74C3C;
}

/*5575FB Zainar blue */
